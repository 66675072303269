import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCompanyInfo } from "../../services";
/** USED TO GET COMPANY INFORMATION */
export const getCompanyInfoAction = createAsyncThunk(
  "getCompanyInfo",
  async (params) => {
    const response = await getCompanyInfo(params);
    return response.data;
  }
);
