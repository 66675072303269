import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  authSelector,
  setAuthAction,
  setLogoutAction,
} from "../store/slice/auth";

const useAuth = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(authSelector);
  const setAuth = useCallback(
    (params) => dispatch(setAuthAction(params)),
    [dispatch]
  );
  const setLogout = useCallback(
    (params) => dispatch(setLogoutAction(params)),
    [dispatch]
  );
  return [
    { data, loading, error },
    { setAuth, setLogout },
  ];
};

export default useAuth;
