import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { loadVariableStyles } from "./utils";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import ToastDialog from "./components/common/ToastDialog";
import i18n from "./translator/i18Helper";
import { issetLocalAuth, setAuthInfo } from "./utils/storage";
import useAuth from "./hooks/useAuth";
import { showToast } from "./store/slice/toast";
import { useDispatch } from "react-redux";

loadVariableStyles();
function Root() {
  const { setAuth } = useAuth();  
  const dispatch = useDispatch();
  const rootElement = document.getElementById("root");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get("lang");
    const externalLoginStatus = params.get('externalLogin');
    if(externalLoginStatus === 'success' && !issetLocalAuth()){
      getUserInfo(setAuth, dispatch);
    }else if (lang === "ar-ae") {
      rootElement.classList.add("root-arabic");
    }
    lang ? i18n.changeLanguage(lang) : i18n.changeLanguage("en");
  }, []);
  return <App />;
}

const getUserInfo = (setAuth, dispatch) => {
  
  // const temp = {
  //   createdAt: "2024-09-17T08:20:11.287Z",
  //   email: "test@gmail.com",
  //   isActive: true,
  //   lastLogin: null,
  //   passwordHash: "AQAAAAIAAYagAAAAEDj/qM23GLbj9QNcXgHtFjPuurKM5bQ6WrLxyBoJgcJDEW/KSv8ZyBbdVd05GpUrdQ==",
  //   userId: 71,
  //   username: "Test"
  // };

  // try {
  //   // Set the user info in the context/state (you might have an actual API call here)
  //   setAuthInfo(temp);
  //   setAuth(temp);

  //   // Show success toast notification
  //   dispatch(showToast({ message: "User logged in successfully.", variant: "success" }));
  // } catch (error) {
  //   console.error('Error fetching user info:', error);
  // }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ToastDialog />
        <Root />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
