const cardConfig = {
  upcoming: ["EARNINGMARKET", "EARNINGSUBSCRIBE", "FINANCIALREMAINDER"],
  today: {
    preMarket: [
      "TICKERPERFORMANCE",
      "PEERSPROFITLOSSCARD",
      "INDICESPROFITLOSSCARD",
    ],
    marketOpen: ["TICKERCHARTTODAYLARGE", "PEERSCHARTTODAYLARGE"],
    marketClose: ["TICKERPERFORMANCE"],
  },
  weekly: [
    "EARNINGSUBSCRIBEAUDIOPLAYER",
    "ANALYSTRANGESLIDER",
    "PROFITANDLOSS",
    "TICKERCHARTWEEKLYLARGE",
    "REVENUEANNUAL",
    "EARNINGSCANDLECHART",
    "EARNINGMARKET",
    "ANALYSTRATINGMETERCHART",
    "FINANCIALHEALTH",
    "FINANCIALPROFITABILITY",
    "EPSANNUALRATIOCHART",
    "RETURNONASSETSRATIOCHART",
    "NETINCOME",
    "DEBTRATIODOTCHART",
    "VALUATIONRATIOCHART",
    "REVENUESTREAM",
    "REVENUESTEAMBYCOUNTRY",
    "DIVIDENDLINECHART",
    "NEWSANNOUNCEMENTS",
    "LASTDIVIDENDPAID",
    "DOCUMENTTAB",
    "SHAREHOLDINGPATTERNLARGE",
    "PEERCOMPARISONCHARTLARGE",
    "COMPOUNDGROWTHCARD",
    "PEERCOMPARISONCHARTLARGE",
    "COMPOUNDGROWTHCARD",
  ],
  monthly: [
    "FINANCIALEVENTS",
    "INDICESCOMPARISONCHARTLARGE",
    "PROFITANDLOSS",
    "COMPOUNDGROWTHCARD",
    "TRANSCRIPTCARD",
    "TRENDCHARTXL",
    "TICKERCHARTMONTHLYLARGE",
    "PEERCOMPARISONCHARTLARGE",
    "EBITDAANNUALBARCHARTXL",
    "REVENUEANNUALBARCHARTXL",
    "REVENUEANDEARNINGS",
    "REVENUEANNUAL",
    "EARNINGSCANDLECHART",
    "ANALYSTRATINGMETERCHART",
    "SHAREHOLDINGPATTERNLARGE",
    "REVENUESTREAM",
    "REVENUESTEAMBYCOUNTRY",
    "ANALYSTRANGESLIDER",
    "REVENUEANNUAL",
    "EARNINGSCANDLECHART",
    "ANALYSTRATINGMETERCHART",
    "SHAREHOLDINGPATTERNLARGE",
    "REVENUESTREAM",
    "REVENUESTEAMBYCOUNTRY",
    "ANALYSTRANGESLIDER",
  ],
  news: ["NEWSANNOUNCEMENTS"],
  qa: [
    "RATINGCHECKBOX1",
    "RATINGCHECKBOX2",
    "RATINGCHECKBOX3",
    "MEETINGREQUEST",
  ],
};

export default cardConfig;
