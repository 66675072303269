import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWiseConfig } from "../../services";

export const getWiseConfigAction = createAsyncThunk(
  "getWiseConfig",
  async (params) => {
    const response = await getWiseConfig(params);
    return response.data;
  }
);
