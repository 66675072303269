import { createSlice } from "@reduxjs/toolkit";
import { getTodayWidgetInfoAction } from "../actions/cardAction";

function getCurrentWeek() {
  const currentDate = new Date();
  const firstDay = currentDate.getDate() - currentDate.getDay(); // First day is the day of the month - the day of the week
  const lastDay = firstDay + 6; // Last day is the first day + 6

  const startOfWeek = new Date(currentDate.setDate(firstDay));
  const endOfWeek = new Date(currentDate.setDate(lastDay));

  // Reset time to midnight for consistency
  startOfWeek.setHours(0, 0, 0, 0);
  endOfWeek.setHours(23, 59, 59, 999);

  return { startOfWeek, endOfWeek };
}
function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
}

const { startOfWeek, endOfWeek } = getCurrentWeek();
const formattedStartOfWeek = formatDate(startOfWeek);
const formattedEndOfWeek = formatDate(endOfWeek);

const initialState = {
  upcomingWidget: {
    name: 'Upcoming',
    subtitle: '',
    details: [],
  },
  todayWidget: {
    name: 'Today',
    subtitle: 'updated every second',
    details: [],
  },
  weeklyWidget: {
    name: 'This week',
    subtitle: `${formattedStartOfWeek} - ${formattedEndOfWeek}`,
    details: [],
  },
  monthlyWidget: {
    name: 'Last 30 Days',
    subtitle: 'updated every month',
    details: [],
  },
  announcementWidget: {
    name: 'News',
    subtitle: '',
    details: [],
  },
  qaWidget: {
    name: 'Q&A',
    subtitle: '',
    details: [],
  },
  instrumentTicker: [],
  loading: false,
  error: null,
};

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    updateWidgets: (state, action) => {
      const { upcomingCards, todayCards, weeklyCards, monthlyCards, announcementCards, qaCards } = action.payload;
      if(upcomingCards) { state.upcomingWidget.details = upcomingCards; }
      if(todayCards) { state.todayWidget.details = todayCards; }
      if(weeklyCards) { state.weeklyWidget.details = weeklyCards; }
      if(monthlyCards) { state.monthlyWidget.details = monthlyCards; }
      if(announcementCards) { state.announcementWidget.details = announcementCards[0]; }
      if(qaCards) { state.qaWidget.details = qaCards[0]; }
    },
    updateInstrument: (state, action) => {
      const { data } = action.payload;
      state.instrumentTicker = data.instruments;
    },
  },
  extraReducers: (builder) => {
   
     /** TODAY */
     builder.addCase(getTodayWidgetInfoAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTodayWidgetInfoAction.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getTodayWidgetInfoAction.rejected, (state, action) => {
      state.fetchError = action.error;
      state.loading = false;
    });
    
  },
});

export default cardSlice;
