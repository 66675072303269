import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTodayWidgetInfo_1A,
  getTodayWidgetInfo_1B,
  getTodayWidgetInfo_1C,
  getTodayWidgetInfo_2A,
  getTodayWidgetInfo_2B,
  getTodayWidgetInfo_2C,
  // getTodayWidgetInfo_3A,
  // getTodayWidgetInfo_3B,
  getWeeklyWidgetInfo,
  getMonthlyWidgetInfo,
  getCompanyAnnouncement,
} from "../../services";
import { selectPrimaryMarketIds } from "../slice/company";
import _ from "lodash";

/** USED TO GET TODAY WIDGET CARDS */
export const getTodayWidgetInfoAction = createAsyncThunk(
  "getInstrument",
  async (params, { getState }) => {
    // const response1 = await getTodayWidgetInfo_1(params);
    // const response2 = await getTodayWidgetInfo_2(params);
    // const response3 = await getTodayWidgetInfo_3(params);
    // const data = response.data;

    const [
      response_1A,
      response_1B,
      // response_1C,
      response_2A,
      // response_2B,
      response_2C,
      // response_3A,
      // response_3B,
    ] = await Promise.all([
      getTodayWidgetInfo_1A(params),
      getTodayWidgetInfo_1B(params),
      // getTodayWidgetInfo_1C(params),
      getTodayWidgetInfo_2A(params),
      // getTodayWidgetInfo_2B(params),
      getTodayWidgetInfo_2C(params),
      // getTodayWidgetInfo_3A(params),
      // getTodayWidgetInfo_3B(params),
    ]);
    const data = _.merge(
      {},
      response_1A.data,
      response_1B.data,
      // response_1C.data,
      response_2A.data,
      // response_2B.data,
      response_2C.data
      // response_3A.data,
      // response_3B.data
    );
    const state = getState();
    const primaryMarketIds = selectPrimaryMarketIds(state);

    return { data, primaryMarketIds };
  }
);

/** USED TO GET WEEKLY WIDGET CARDS */
export const getWeeklyWidgetInfoAction = createAsyncThunk(
  "getInstrument",
  async (params, { getState }) => {
    const response = await getWeeklyWidgetInfo(params);
    const data = response.data;
    const state = getState();
    const primaryMarketIds = selectPrimaryMarketIds(state);

    return { data, primaryMarketIds };
  }
);

/** USED TO GET MONTHLY WIDGET CARDS */
export const getMonthlyWidgetInfoAction = createAsyncThunk(
  "getInstrument",
  async (params, { getState }) => {
    const response = await getMonthlyWidgetInfo(params);
    const data = response.data;
    const state = getState();
    const primaryMarketIds = selectPrimaryMarketIds(state);

    return { data, primaryMarketIds };
  }
);

/** USED TO GET COMPANY ANNOUNCEMENT WIDGET */
export const getCompanyAnnouncementAction = createAsyncThunk(
  "getCompanyAnnouncement",
  async (params) => {
    const response = await getCompanyAnnouncement(params);
    const data = response.data;
    return { data };
  }
);
