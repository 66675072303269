import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../assets/locale/en-gb.json";
import translationAR from "../assets/locale/ar-ae.json";
import translationDK from "../assets/locale/dk-ae.json";
import translationES from "../assets/locale/es.json";
import translationFI from "../assets/locale/fi.json";
import translationNO from "../assets/locale/no.json";
import translationSV from "../assets/locale/sv-ae.json";

const resources = {
  en: { translation: translationEN },
  "en-gb": { translation: translationEN },
  ar: { translation: translationAR },
  "ar-ae": { translation: translationAR },
  dk: { translation: translationDK },
  "dk-ae": { translation: translationDK },
  es: { translation: translationES },
  fi: { translation: translationFI },
  no: { translation: translationNO },
  sv: { translation: translationSV },
  "sv-ae": { translation: translationSV },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export const t = (key, options) => i18n.t(key, options);

export default i18n;
