export default {
  apiBaseUrl: process.env.REACT_APP_MASTER_API_BASE_URL,
  apiBaseUrlNgrok: process.env.REACT_APP_MASTER_API_BASE_URL_2,
  authBaseUrl: process.env.REACT_APP_MASTER_API_BASE_URL_3,
  externalAuthUrl: process.env.REACT_APP_EXTERNAL_AUTH_URL,
  log: false,
  trackHttpTimeInConsole: false, // Works when log is true
  trackHttpResponseInConsole: false, // Works when log is true,
  OPENAI_KEY: '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'
};
