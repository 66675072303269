import { combineReducers } from "redux";
import authSlice from "./slice/auth";
import cardSlice from "./slice/card";
import companySlice from "./slice/company";
import announcementSlice from "./slice/announcements";
import toastSlice from './slice/toast';
import wiseConfigSlice from "./slice/wiseConfig";

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  card: cardSlice.reducer,
  company: companySlice.reducer,
  wiseConfig: wiseConfigSlice.reducer,
  announcement: announcementSlice.reducer,
  toast: toastSlice.reducer,
});
