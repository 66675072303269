import moment from "moment-timezone";
import { t } from "../translator/i18Helper";

const {
  format,
  subDays,
  subMonths,
  subYears,
  startOfYear,
} = require("date-fns");

export const compareObject = (a = {}, b = {}) => {
  if (a === b) return true;
  if (
    (Array.isArray(a) && !Array.isArray(b)) ||
    (!Array.isArray(a) && Array.isArray(b))
  )
    return false;
  const aggregate = { ...a, ...b };
  for (const key in aggregate) {
    if (typeof a[key] === "object" && typeof b[key] === "object") {
      return compareObject(a[key], b[key]);
    }
    if (a[key] !== b[key]) return false;
  }
  return true;
};

/**
 *
 * @param {Record<string,any>} data
 * @returns {Record<string,any>}
 */
export const trimObjectProperties = (data = {}) => {
  const newData = { ...data };
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      if (typeof element === "string") {
        newData[key] = element.trim();
      }
    }
  }
  return newData;
};

export const isObjectEmpty = (objectName) => {
  return (
    Object.keys(objectName).length === 0 && objectName.constructor === Object
  );
};

export const isArrayEmpty = (array) => {
  return array.length === 0;
};

export const isValidType = (name, allowType) => {
  for (let j = 0; j < allowType.length; j++) {
    let sCurExtension = allowType[j];
    if (
      name
        .substr(name.length - sCurExtension.length, sCurExtension.length)
        .toLowerCase() === sCurExtension.toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

export const validateFiles = (fileArray, allowFileType) => {
  return fileArray.filter((file) => isValidType(file.name, allowFileType));
};

export const hasScrollBar = (className) => {
  const element = document.querySelector(className);
  if (!element) {
    return false;
  }
  const hasScrollBar =
    element.clientHeight < element.scrollHeight ||
    element.clientWidth < element.scrollWidth;

  return hasScrollBar;
};

export const normalizeData = (data) => {
  const normalizedData = {
    entities: {},
    result: [],
  };

  data.forEach((item) => {
    const { id } = item;
    normalizedData.entities[id] = item;
    normalizedData.result.push(id);
  });

  return normalizedData;
};

export const randomizeData = (data) => {
  if (data) {
    for (let i = data.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [data[i], data[j]] = [data[j], data[i]];
    }
  }
  return data;
};
export const convertDateTimeZone = (date, timeZone) => {
  // Convert the dateTime to the local time in the specified time zone (e.g., Stockholm)
  const localMoment = moment.tz(date, timeZone);
  return localMoment.valueOf();
};
export const convertToValuesArray = (
  data,
  dateVariable,
  valueVariable,
  timeZone
) => {
  if (!data) return data;
  return data.map((item) => {
    const value = parseFloat(item[valueVariable]);
    const localTimeInMilliseconds = convertDateTimeZone(
      item[dateVariable],
      timeZone
    );
    return [localTimeInMilliseconds, value];
  });
};
// export const findMarketTime = (timeLocal, timeZone, date) => {
//   const dateInMarket = date
//     ? moment.tz(date, timeZone).format("YYYY-MM-DD")
//     : moment.tz(timeZone).format("YYYY-MM-DD"); // find market date
//   return moment.tz(`${dateInMarket} ${timeLocal}`, timeZone).valueOf();

// };
export const findMarketTime = (timeLocal, timeZone, dateInMilliseconds) => {
  if (!timeLocal || !timeZone || !dateInMilliseconds) return null; // Convert base milliseconds to date in the specified timezone
  const baseDate = moment.tz(dateInMilliseconds, timeZone);

  // Parse the local time and set it on the base date
  const [hour, minute, second] = timeLocal.split(":").map(Number);
  const newDateTime = baseDate.set({ hour, minute, second, millisecond: 0 });

  // Return the new milliseconds timestamp
  return newDateTime.valueOf();
};
export const generateTimeIntervals = (openTimeLocal, closeTimeLocal) => {
  // Use current date for the time manipulation
  const date = moment().format("YYYY-MM-DD");

  // Start time and end time
  const startTime = moment(`${date} ${openTimeLocal}`);
  const endTime = moment(`${date} ${closeTimeLocal}`);

  // Calculate the total duration and the interval duration
  const totalDuration = endTime.diff(startTime, "minutes");
  const intervalDuration = totalDuration / 3; // Divide into 3 equal parts to get 4 intervals

  // Create an array to store the intervals
  const intervals = [];

  // Calculate and add each interval
  for (let i = 0; i < 4; i++) {
    const intervalTime = startTime.clone().add(i * intervalDuration, "minutes");
    intervals.push(intervalTime.format("h:mma"));
  }

  return intervals;
};
// const generateTimeIntervals = (openTimeLocal, closeTimeLocal) => {
//   const intervals = [];

//   // Use current date for the time manipulation
//   const date = moment().format("YYYY-MM-DD");

//   // Start time and end time without timezone conversion
//   const startTime = moment(`${date} ${openTimeLocal}`);
//   const endTime = moment(`${date} ${closeTimeLocal}`);

//   // Push the first interval (open time)
//   intervals.push(startTime.format("h:mma"));

//   // Calculate the total duration and midpoint
//   const totalDuration = endTime.diff(startTime, "minutes");
//   const halfDuration = Math.round(totalDuration / 2); // Half of the total duration in minutes

//   // Define intervals based on halfway points
//   const secondInterval = startTime.clone().add(halfDuration / 2, "minutes");
//   const thirdInterval = startTime.clone().add(halfDuration, "minutes");

//   // Round times to the nearest 30 minutes
//   const roundToNearest30Minutes = (time) => {
//     return time
//       .startOf("minute")
//       .add(Math.round(time.minute() / 30) * 30, "minutes");
//   };

//   const roundedSecondInterval = roundToNearest30Minutes(secondInterval);
//   const roundedThirdInterval = roundToNearest30Minutes(thirdInterval);

//   // Add the second and third intervals
//   intervals.push(roundedSecondInterval.format("h:mma"));
//   intervals.push(roundedThirdInterval.format("h:mma"));

//   // Push the last interval (close time)
//   intervals.push(endTime.format("h:mma"));

//   // Ensure exactly 4 intervals
//   return intervals;
// };
export const findGraphData = (title, data) => {
  const item = data?.find((e) => e.title === title);
  return item ? item.details[0]?.graph : null;
};

export const intradayLineColor = (previousClose, currentValue) => {
  let colorCode = {};
  if (
    previousClose !== null &&
    previousClose !== undefined &&
    currentValue !== null &&
    currentValue !== undefined
  ) {
    previousClose = Number(previousClose);
    currentValue = Number(currentValue);
    if (previousClose < currentValue) {
      colorCode = { color: "#27AE60", lite: "#67C27B" }; // green & lite green
    } else {
      colorCode = { color: "#EB5757", lite: "#FF7A7A" }; // red & lite red
    }
  }
  return colorCode;
};
export const calculateLineColor = (val) => {
  let color = "";
  let colorCode = "";
  if (val !== null && val !== undefined) {
    const v = Number(val);
    if (v === 0) {
      color = "grey";
      colorCode = "#EB5757"; // red
    } else if (v > 0) {
      color = "green";
      colorCode = "#27AE60";
    } else if (v < 0) {
      color = "red";
      colorCode = "#EB5757";
    }
  }
  return { color, colorCode };
};
export const calculateTrend = (val) => {
  let trend = "";
  if (val !== null && val !== undefined) {
    const v = Number(val);
    if (v >= -2 && v <= 2) {
      trend = t("sideways");
    } else if (v > 2) {
      trend = t("uptrend");
    } else if (v < -2) {
      trend = t("downtrend");
    }
  }
  return trend;
};

export const formatChangeValue = (value) => {
  const number = Number(value);
  if (isNaN(number)) return value;
  return number > 0 ? `+${number.toFixed(2)}` : `${number.toFixed(2)}`;
};

export const formatChangePercentage = (value) => {
  const number = Number(value);
  if (isNaN(number)) return value;
  return number > 0 ? `+${number.toFixed(2)}%` : `${number.toFixed(2)}%`;
};

export const formatSymbol = (symbol) => {
  return symbol ? symbol.replace(/ /g, "-") : symbol;
};

export const formatDecimal = (value, decimals = 2) => {
  const number = Number(value);
  if (isNaN(number)) return "-";
  return parseFloat(number).toFixed(decimals);
};

// Custom function to format numbers with commas
export const formatWithCommas = (num) => {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
export const numberFormatter = (num, type = "volume", suffix = true) => {
  if (num === "" || num === null || num === undefined)
    return type === "quarterly" ? "--" : "-";

  const number = Number(num);
  if (isNaN(number)) return "-";
  const absNum = Math.abs(number);

  const suffixes = {
    volume: ["T", "B", "M", "L", "K"],
    earnings: ["t", "b", "m", "l", "k"],
  };

  const chosenSuffixes = suffixes[type] || suffixes.volume;
  const formattedNum =
    type === "earnings" ? absNum : Math.sign(number) * absNum;

  // Function to format volume without decimals
  const formatVolume = (num) => formatWithCommas(num);

  if (suffix) {
    if (absNum >= 1e12) {
      return formatVolume((formattedNum / 1e12).toFixed(2)) + chosenSuffixes[0];
    } else if (absNum >= 1e9) {
      return formatVolume((formattedNum / 1e9).toFixed(2)) + chosenSuffixes[1];
    } else if (absNum >= 1e6) {
      return formatVolume((formattedNum / 1e6).toFixed(2)) + chosenSuffixes[2];
    } else if (absNum >= 1e5) {
      return (
        formatWithCommas((formattedNum / 1e5).toFixed(2)) + chosenSuffixes[3]
      );
    } else if (absNum >= 1e3) {
      return (
        formatWithCommas((formattedNum / 1e3).toFixed(2)) + chosenSuffixes[4]
      );
    }
  } else {
    if (absNum >= 1e12) {
      return formatVolume((formattedNum / 1e12).toFixed(2));
    } else if (absNum >= 1e9) {
      return formatVolume((formattedNum / 1e9).toFixed(2));
    } else if (absNum >= 1e6) {
      return formatVolume((formattedNum / 1e6).toFixed(2));
    } else if (type === "earnings") {
      if (absNum >= 1e5) {
        return formatWithCommas((formattedNum / 1e5).toFixed(2));
      } else if (absNum >= 1e3) {
        return formatWithCommas((formattedNum / 1e3).toFixed(2));
      }
    }
  }
  return formattedNum.toFixed(2);
};

// export const numberFormatter = (num, type = "volume", suffix = true) => {
//   if (num === "" || num === null || num === undefined)
//     return type === "quarterly" ? "--" : "-";

//   const number = Number(num);
//   if (isNaN(number)) return "-";
//   const absNum = Math.abs(number);

//   const suffixes = {
//     volume: ["T", "B", "M", "L", "K"],
//     earnings: ["t", "b", "m", "l", "k"],
//   };

//   const chosenSuffixes = suffixes[type] || suffixes.volume;
//   const formattedNum =
//     type === "earnings" ? absNum : Math.sign(number) * absNum;

//   // Function to format volume without decimals
//   const formatVolume = (num) => {
//     return formatWithCommas(Math.round(num));
//   };

//   if (absNum >= 1e12) {
//     return formatVolume(formattedNum / 1e12) + chosenSuffixes[0];
//   } else if (absNum >= 1e9) {
//     return formatVolume(formattedNum / 1e9) + chosenSuffixes[1];
//   } else if (absNum >= 1e6) {
//     return formatVolume(formattedNum / 1e6) + chosenSuffixes[2];
//   } else if (type === "earnings") {
//     if (absNum >= 1e5) {
//       return (
//         formatWithCommas((formattedNum / 1e5).toFixed(2)) + chosenSuffixes[3]
//       );
//     } else if (absNum >= 1e4) {
//       return (
//         formatWithCommas((formattedNum / 1e4).toFixed(2)) + chosenSuffixes[4]
//       );
//     }
//   } else {
//     return formatVolume(formattedNum);
//   }
// };
export const getPeriodDescription = (period) => {
  switch (period) {
    case "ANL":
      return "Annual";
    case "FNL":
      return "Final";
    case "INS":
      return "Installment";
    case "INT":
      return "Interim";
    case "IRG":
      return "Irregular";
    case "ISC":
      return "Capital";
    case "MEM":
      return "Memorial";
    case "MNT":
      return "Monthly";
    case "ONE":
      return "Once";
    case "QTR":
      return "Quarterly";
    case "REG":
      return "Regular";
    case "SMA":
      return "Semi-Annual";
    case "SPL":
      return "Special";
    case "SUP":
      return "Supplementary";
    case "TRM":
      return "Trimesterly";
    case "UN":
      return "Unspecified";
    case "WKY":
      return "Weekly";
    case "CRP":
      return "Capital Reduction";
    default:
      return period;
  }
};

export const getDateRanges = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const dateFormat = "yyyy-MM-dd";
  // Today's date formatted
  const formattedToday = format(today, dateFormat);
  // Yesterday's date formatted
  const yesterDay = subDays(today, 1);
  const formattedYesterDay = format(yesterDay, dateFormat);

  // Last 7 days (week) range
  const startOfLast7Days = subDays(today, 7);
  const formattedStartOfLast7Days = format(startOfLast7Days, dateFormat);
  const last7DaysRange = `{
    gte: "${formattedStartOfLast7Days}T00:00:00Z",
    lte: "${formattedToday}T23:59:59Z"
  }`;

  // Last 30 days (month) range
  const startOfLast30Days = subDays(today, 30);
  const formattedStartOfLast30Days = format(startOfLast30Days, dateFormat);
  const last30DaysRange = `{
    gte: "${formattedStartOfLast30Days}T00:00:00Z",
    lte: "${formattedToday}T23:59:59Z"
  }`;

  // Last 3 months range
  const startOfLast3Months = subMonths(today, 3);
  const formattedStartOfLast3Months = format(startOfLast3Months, dateFormat);
  const last3MonthsRange = `{
    gte: "${formattedStartOfLast3Months}T00:00:00Z",
    lte: "${formattedToday}T23:59:59Z"
  }`;
  // Last 6 months range
  const startOfLast6Months = subMonths(today, 6);
  const formattedStartOfLast6Months = format(startOfLast6Months, dateFormat);
  const last6MonthsRange = `{
    gte: "${formattedStartOfLast6Months}T00:00:00Z",
    lte: "${formattedToday}T23:59:59Z"
  }`;

  // Start of the current year to current date
  const startOfCurrentYear = startOfYear(today);
  const formattedStartOfCurrentYear = format(startOfCurrentYear, dateFormat);
  const currentYearRange = `{
    gte: "${formattedStartOfCurrentYear}T00:00:00Z",
    lte: "${formattedToday}T23:59:59Z"
  }`;

  // Last 12 months range
  const startOfLast12Months = subMonths(today, 12);
  const formattedStartOfLast12Months = format(startOfLast12Months, dateFormat);
  const last12MonthsRange = `{
    gte: "${formattedStartOfLast12Months}T00:00:00Z",
    lte: "${formattedToday}T23:59:59Z"
  }`;

  // Last 5 years range
  const startOfLast5Years = subYears(today, 5);
  const formattedStartOfLast5Years = format(startOfLast5Years, dateFormat);
  const last5YearsRange = `{
    gte: "${formattedStartOfLast5Years}T00:00:00Z",
    lte: "${formattedToday}T23:59:59Z"
  }`;

  return {
    today: formattedToday,
    last30Day: formattedStartOfLast30Days,
    last3Months: formattedStartOfLast3Months,
    last6Months: formattedStartOfLast6Months,
    last12Months: formattedStartOfLast12Months,
    currentYear: currentYear,
    startOfCurrentYear: formattedStartOfCurrentYear,
    todayRange: `{
      gte: "${formattedToday}T00:00:00Z",
      lte: "${formattedToday}T23:59:59Z"
    }`,
    yesterDayRange: `{
      gte: "${formattedYesterDay}T00:00:00Z",
      lte: "${formattedYesterDay}T23:59:59Z"
    }`,
    last7DaysRange,
    last30DaysRange,
    last3MonthsRange,
    last6MonthsRange,
    currentYearRange,
    last12MonthsRange,
    last5YearsRange,
  };
};

export const convertMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return { hours, remainingMinutes };
};
export const calculatePercentageChange = (current, previous) => {
  return ((current - previous) / previous) * 100;
};

export const getMonthName = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "long" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const getFormattedTime = (dateTimeString) => {
  // Extract hours, minutes, and seconds '2024-08-27T10:01:40.713Z'
  const dateObject = new Date(dateTimeString);
  const hours = dateObject.getUTCHours().toString().padStart(2, "0");
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, "0");

  return { hours, minutes };
};

export const getYearsArray = (year, length) => {
  const yearsArr = Array.from({ length: length }, (_, index) => year - index);
  return yearsArr;
};

export const GetSliderWidgets = (cardInfo) => {
  const lastLargeIndex = cardInfo
    ?.map((obj) => obj?.large || obj?.extraLarge)
    .lastIndexOf(true);
  const remainingValues = cardInfo?.slice(lastLargeIndex + 1);
  if (remainingValues?.length === 0) {
    return 1;
  } else if (remainingValues.length >= 2 && remainingValues.length <= 3) {
    return 3;
  } else if (remainingValues.length >= 4) {
    return 4;
  } else {
    return 2;
  }
};
